import type { InvalidAnswerReason, TideAsset } from '@/types'
import type { TideQuestion } from '@feedbackloop/shared'

export const LimitedTextareaMaxCharacters = 50

export interface TextAreaProps {
  answerAttemptCount: number
  asset: TideAsset | null
  currentValue: string
  errorReasons: InvalidAnswerReason[]
  isMobile: boolean
  isValidAnswer: boolean
  maxlength: number | null
  question: TideQuestion
  questionId: string // used to force re-rendering
  showError: boolean
}
