import type { SubmittedAnswer, TideQuestion } from '@feedbackloop/shared'
import type { QuestionType } from '@feedbackloop/types'
import { ReefQuestionType } from '@feedbackloop/types-graphql'

export enum RedFlag {
  Paster = 'paster',
  Speeder = 'speeder',
  Spam = 'spam',
  BlacklistedIP = 'blacklist_ip',
  SampleChainThreat = 'sc_high_threat_potential',
  NotQualify = 'not_qualify',
  NotEnoughAnswers = 'not_enough_answers',
  Plagiarized = 'plagiarized'
}

export function questionTypeLOI (type: QuestionType): number {
  switch (type) {
    case ReefQuestionType.Description:
    case ReefQuestionType.Number:
    case ReefQuestionType.Variant:
    case ReefQuestionType.MultiSelect:
    case ReefQuestionType.SingleSelect:
    case ReefQuestionType.RateOnly:
    case ReefQuestionType.DirectComparison:
      return 2
    case ReefQuestionType.Rank:
    case ReefQuestionType.Rate:
    case ReefQuestionType.LimitedTextArea:
    case ReefQuestionType.TextArea:
      return 3
    case ReefQuestionType.Matrix:
      return 4
    default: // for any types of questions that aren't listed already
      return 1
  }
}

const SPAM_RESPONSES = ['cuz', 'lol', 'nada', 'idk', 'blah']

export function isSpam (text: string): boolean {
  return SPAM_RESPONSES.includes(cleanText(text))
}

export function cleanText (text: string): string {
  return text.trim().toLowerCase()
}

export function getFreeResponseFromSubmittedAnswer (answer: SubmittedAnswer, question: TideQuestion): string | null {
  if (question.type === ReefQuestionType.TextArea || question.type === ReefQuestionType.LimitedTextArea) {
    return answer.value
  }
  if (question.type === ReefQuestionType.Rate) {
    return answer.explainAnswer!
  }
  // Only return single/multi selects 'other' field, we are ignoring the explain for now
  // for parity with gentest, what our long term idea is, is tbd...
  if (question.type === ReefQuestionType.SingleSelect && question.otherOpt && answer.value === 'Other') {
    return answer.otherAnswer!
  }
  if (question.type === ReefQuestionType.MultiSelect && question.otherOpt && answer.value.includes('Other')) {
    return answer.otherAnswer!
  }
  return null
}
